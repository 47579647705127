import React from 'react'
import { Typography, Button, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { urls } from '../../utils/config'

const useStyles = makeStyles((theme) => ({
  root: {
    color: '#FFFFFF',
    background: '#211A29',
    padding: '20px',
    [theme.breakpoints.up('sm')]: {
      padding: '120px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '160px',
    },
  },
  sectionTitle: {
    fontFamily: theme.typography.h1.fontFamily,
    fontWeight: 'bold',
  },
  helpLinkHeader: {
    color: '#FFFFFF',
    margin: '1rem 0',
  },
  button: {
    width: '100%',
    background: 'rgba(255,255,255,0.06)',
    height: 'unset',
    borderRadius: 8,
    padding: '16px 24px',
    fontFamily: theme.typography.fontFamily,
    textTransform: 'none',
    fontWeight: 'normal',
    fontSize: '16px',
    letterSpacing: '0',
    color: '#FFFFFF',
    justifyContent: 'space-between',
    margin: '8px 0',
  },
  icon: {
    color: '#FFFFFF',
  },
  links: {
    display: 'flex',
    flexDirection: 'column',
  },
  gridContainer: {
    width: '100%',
    margin: 0,
  },
}))

const helpLinks = {
  getStarted: [
    { label: 'Getting started with Crypto', url: urls.academy },
    // { label: 'Frequently asked questions', url: '' },
    { label: 'Crypto Academy', url: urls.academy },
    { label: 'Exchanges', url: urls.exchanges },
    { label: 'Wallets', url: urls.custodialWallets },
    { label: 'Earn', url: urls.savingAccounts },
    { label: 'Rewards', url: urls.cryptoCards },
  ],
  community: [
    { label: 'Telegram', url: urls.telegram },
    { label: 'Twitter', url: urls.twitter },
    { label: 'Podcasts', url: urls.podcast },
  ],
}

function HelpSection(): JSX.Element {
  const classes = useStyles({})

  return (
    <div className={classes.root}>
      <Typography variant="h2" className={classes.sectionTitle}>
        Need Help?
      </Typography>
      <Grid container spacing={8} className={classes.gridContainer}>
        <Grid item xs={12} md={6} lg={5}>
          <Typography className={classes.helpLinkHeader}>Get Started</Typography>
          <div className={classes.links}>
            {helpLinks.getStarted.map((link, index) => (
              <Button
                key={index}
                className={classes.button}
                endIcon={<ArrowForwardIcon className={classes.icon} />}
                href={link.url}
              >
                {link.label}
              </Button>
            ))}
          </div>
        </Grid>
        <Grid item xs={12} md={6} lg={5}>
          <Typography className={classes.helpLinkHeader}>Community</Typography>
          <div>
            {helpLinks.community.map((link, index) => (
              <Button
                key={index}
                className={classes.button}
                endIcon={<ArrowForwardIcon className={classes.icon} />}
                href={link.url}
              >
                {link.label}
              </Button>
            ))}
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default HelpSection
