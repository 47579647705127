import React from 'react'
import { Card, CardActionArea, CardContent, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { linkType } from './ExploreNav'
import Image from 'next/image'

interface styleProps {
  backgroundColor: string
}

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '1rem',
    overflow: 'hidden',
    background: 'rgba(255, 255, 255, 0.1)',
  },
  viewMoreRoot: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    color: theme.palette.primary.main,
  },
  image: {
    alignSelf: 'center',
    position: 'relative',
    borderRadius: '4px',
    marginTop: '2rem',
    [theme.breakpoints.up('md')]: {
      width: '80%',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  viewMoreContent: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontSize: '16px',
    fontWeight: 700,
    fontFamily: theme.typography.h1.fontFamily,
    color: 'white',
  },
  actionArea: {
    maxWidth: '320px',
    padding: '1rem',
    minHeight: '16rem',
    '&:hover $focusHighlight': {
      opacity: 0,
    },
    [theme.breakpoints.up('md')]: {
      minHeight: '20rem',
    },
  },
  focusHighlight: {},
  viewMoreIcon: {
    background: 'rgba(43, 24, 65, 0.06)',
    padding: '10px 20px',
    borderRadius: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

interface Props {
  link: linkType
}

function ExploreNavCard({ link }: Props): JSX.Element {
  const { title, url, image } = link
  const classes = useStyles({})

  return (
    <Card elevation={0} className={classes.root}>
      <CardActionArea
        classes={{ root: classes.actionArea, focusHighlight: classes.focusHighlight }}
        href={url}
        disableRipple
      >
        <CardContent className={classes.content}>
          <Typography variant="body1" className={classes.title}>
            {title}
          </Typography>
          <div className={classes.image}>
            <Image layout="intrinsic" height={230} width={230} src={image?.url} />
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default ExploreNavCard
