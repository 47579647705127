// 3rd party libraries
import { Button, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { NextSeo } from 'next-seo'
import Link from 'next/link'
import React from 'react'
import { GetStaticProps } from 'next'
import { Amplitude, LogOnMount } from '@amplitude/react-amplitude'

// components
import JoinCommunitySection from '../src/components/Footer/JoinCommunitySection'
import HelpSection from '../src/components/Help/HelpSection'
import ExploreNav from '../src/components/Homepage/ExploreNav'
import ProductSection from '../src/components/Product/Explorer'
import Nav from '../src/components/Nav'
import Footer from '../src/components/Footer'
import Testimonial from '../src/components/Homepage/Testimonial'

// functions
import { urls } from '../src/utils/config'
import ExchangesSection from '../src/components/Homepage/ExchangesSection'

// types
import { Wallet } from '../types/wallet'
import { Exchange } from '../types/exchange'
import { SavingAccount } from '../types/savingAccount'
import { CryptoCard } from '../types/cryptoCard'

// services
import { getExchanges } from '../src/services/exchanges'
import { getWallets } from '../src/services/wallets'
import { getSavingsAccountFromStrapi } from '../src/services/savingAccounts'
import { getCryptoCards } from '../src/services/cards'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '1200px',
    margin: 'auto',
    padding: '0 1rem',
  },
  pageWrapper: {
    background: 'black',
    overflow: 'auto',
  },
  pageGradient: {
    background: 'linear-gradient(180deg, rgba(137, 93, 209, 0.3) , black 50% )',
    overflow: 'hidden',
  },
  section: {
    margin: '180px 0',
    [theme.breakpoints.down('sm')]: {
      margin: '50px 0',
    },
    '&>$title': {
      textAlign: 'left',
      maxWidth: '420px',
      alignSelf: 'left',
      margin: '2rem 0',
    },
  },
  aboveFold: {
    minHeight: '20vh',
  },
  backgroundImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: -1,
  },
  title: {
    margin: '20px auto 0 auto',
    color: 'white',
    textAlign: 'center',
    maxWidth: '620px',
    letterSpacing: '-0.02em',
    fontFamily: theme.typography.h1.fontFamily,
    [theme.breakpoints.down('xs')]: {
      fontSize: '2rem',
      maxWidth: '100%',
      padding: '20px',
      margin: '0 auto 0 auto',
    },
  },
  heroCTA: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',

    '& a, button': {
      margin: '.4rem 0',
      textTransform: 'none',
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      '& a, button': {
        margin: '0 1rem',
      },
    },
  },
  underline: {
    textDecoration: 'underline',
  },
  smallSubtitle: {
    left: '-10px',
    marginBottom: '20px',
    position: 'relative',
    width: 'fit-content',
    '& h5': {
      fontSize: '16px',
      color: theme.palette.primary.main,
      position: 'relative',
    },
  },
  subtitle: {
    marginBottom: '30px',
    position: 'relative',
    width: 'fit-content',
    color: 'white',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
    },
    '& h5': {
      color: 'white',
      position: 'relative',
    },
  },
  smallSubtitleBackground: {
    position: 'absolute',
    top: '5px',
    left: '-5px',
    width: '100%',
    backgroundColor: theme.palette.secondary.main,
    height: '18px',
    zIndex: -1,
  },
  subtitleBackground: {
    position: 'absolute',
    top: '15px',
    left: '-10px',
    width: '100%',
    backgroundColor: theme.palette.secondary.main,
    height: '18px',
    zIndex: -1,
  },
  subSection: {
    display: 'flex',
    flexDirection: 'row',
  },
  textSection: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '50px',
    padding: '20px',
    [theme.breakpoints.up('md')]: {
      maxWidth: '50%',
    },
  },
  homeImage: {
    maxWidth: '80%',
    margin: '0 0 20px auto',
    objectFit: 'contain',
    maxHeight: '300px',
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  heroContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  heroChip: {
    background: 'rgba(255, 255, 255, 0.06)',
    borderRadius: '12px',
    display: 'flex',
    alignItems: 'center',
    padding: '.4rem .1rem',

    '&:hover': {
      cursor: 'pointer',
    },

    '&>*': {
      margin: '0 .4rem',
      color: '#C4C4C4',
    },
    '& > p': {
      fontSize: '.8rem',
    },
  },
}))

interface Props {
  wallets: Wallet[]
  exchanges: Exchange[]
  savingAccounts: SavingAccount[]
  cards: CryptoCard[]
}

function Home({ wallets, exchanges, savingAccounts, cards }: Props): JSX.Element {
  const classes = useStyles({})

  return (
    <Amplitude
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      eventProperties={(inheritedProps: { page: any }) => ({
        ...inheritedProps,
        page: {
          ...inheritedProps.page,
          name: urls.home,
        },
      })}
    >
      {(): JSX.Element => (
        <>
          <LogOnMount eventType="page-displayed" />
          <NextSeo
            title={`Cryptotesters | The #1 Crypto Product Comparison Platform`}
            description={`Cryptotesters is your go-to resource to navigate the crypto universe. We help you find the best cryptocurrency exchanges, wallets and much more.`}
            openGraph={{
              url: `/`,
              title: `Cryptotesters | The #1 Crypto Product Comparison Platform`,
              description: `Cryptotesters is your go-to resource to navigate the crypto universe. We help you find the best cryptocurrency exchanges, wallets and much more.`,
              images: [
                {
                  url: `https://${process.env.NEXT_PUBLIC_APP_URL}/og/pages/large/home.jpg`,
                  width: 1200,
                  height: 630,
                  alt: `Cryptotesters.com`,
                },
                {
                  url: `https://${process.env.NEXT_PUBLIC_APP_URL}/og/pages/small/home.jpg`,
                  width: 600,
                  height: 400,
                  alt: `Cryptotesters.com Logo`,
                },
              ],
              site_name: 'Cryptotesters.com',
            }}
            twitter={{
              handle: '@cryptotesters',
              site: '@cryptotesters',
              cardType: 'summary_large_image',
            }}
          />
          <div className={classes.pageWrapper}>
            <div className={classes.pageGradient}>
              <Nav />
              <div className={classes.root}>
                <div className={classes.aboveFold}>
                  <div className={classes.heroContent}>
                    {/* <Link href="#">
                      <div className={classes.heroChip}>
                        <Chip label="New" size="small" color="primary" />
                        <Typography variant="body1">
                          We now have Stakings in our Products
                        </Typography>
                        <ArrowForward fontSize="small" />
                      </div>
                    </Link> */}
                    <Typography variant="h1" className={classes.title}>
                      Find the best crypto products
                    </Typography>
                    <Typography variant="body1" className={classes.subtitle}>
                      Find the right product for your purpose. We test our products with a huge
                      support of our big community.
                    </Typography>
                    <div className={classes.heroCTA}>
                      <Button href={urls.exchanges} color="primary" variant="contained">
                        Compare Exchanges
                      </Button>
                      <Link href={urls.academy}>
                        <Button className={classes.underline}>Learn more about crypto</Button>
                      </Link>
                    </div>
                  </div>
                </div>
                <section className={`${classes.section}`}>
                  <Typography variant="h4" className={classes.title}>
                    Most popular crypto products
                  </Typography>
                  <ProductSection
                    wallets={wallets}
                    exchanges={exchanges}
                    savingAccounts={savingAccounts}
                    cards={cards}
                  />
                </section>
                <section className={`${classes.section}`}>
                  <Typography variant="h4" className={classes.title}>
                    Explore Cryptotesters
                  </Typography>
                  <ExploreNav />
                </section>
                <section className={`${classes.section}`}>
                  <Testimonial />
                </section>
              </div>
            </div>
          </div>
          <div style={{ background: 'white' }}>
            <ExchangesSection />
            <HelpSection />
            <JoinCommunitySection />
            <Footer />
          </div>
        </>
      )}
    </Amplitude>
  )
}

export const getStaticProps: GetStaticProps = async () => {
  let wallets: Wallet[] = []
  let exchanges: Exchange[] = []
  let savingAccounts: SavingAccount[] = []
  let cards: CryptoCard[] = []

  try {
    wallets = await getWallets()
    exchanges = await getExchanges(['exchanges'])
    cards = await getCryptoCards()
    savingAccounts = await getSavingsAccountFromStrapi('CeFi')
  } catch (e) {
    console.error(e)
  }

  return {
    props: {
      wallets,
      exchanges,
      savingAccounts,
      cards,
    },
  }
}

export default Home
