import React from 'react'
import Grid from '@material-ui/core/Grid'
import ProductCard from './ProductCard'
import { Exchange as ExchangeType } from '../../../types/exchange'
import { Wallet as WalletType } from '../../../types/wallet'
import { Deal as DealType } from '../../../types/deal'
import { StakingPlatform as PlatformType } from '../../../types/stakingPlatform'
import { CryptoCard as CryptoCardType } from '../../../types/cryptoCard'

interface Props {
  products: (WalletType & ExchangeType & DealType & PlatformType & CryptoCardType)[]
}

function ProductGrid({ products }: Props): JSX.Element {
  return (
    <Grid container justify="flex-start" spacing={2}>
      {products.map((product) => (
        <Grid item xs={6} md={4} key={product._id}>
          <ProductCard product={product} />
        </Grid>
      ))}
    </Grid>
  )
}

export default ProductGrid
