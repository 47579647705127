import { Chip, Grid, Link, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import Image from 'next/image'
import { urls } from '../../utils/config'
import ArrowForward from '@material-ui/icons/ArrowForward'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '40px 20px',
    [theme.breakpoints.up('sm')]: {
      padding: '120px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '160px',
    },
    '& p': {
      color: 'black',
      margin: '1rem 0',
    },
    '& > div:nth-child(1)': {
      margin: ' 0 0 10rem 0',
    },
  },
  titleSection: {
    width: '100%',
    marginBottom: '4rem',
    [theme.breakpoints.up('md')]: {
      width: '70%',
    },
  },
  title: {
    fontSize: '2rem',
    fontFamily: theme.typography.h1.fontFamily,
    fontWeight: 800,
    lineHeight: 1,
  },
  link: {
    color: 'black',
    transition: 'transform 0.3s ease-in-out',
    borderBottom: '1px solid rgba(43, 24, 65, 0.13)',
    paddingBottom: '.2rem',
    fontSize: '.8rem',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  chip: {
    borderRadius: '.5rem',
    bkacground: 'rgba(0, 0, 0, 0.04)',
  },
  learnCard: {
    background: 'rgba(0, 0, 0, 0.04)',
    borderRadius: '.8rem',
    padding: '2rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& .title': {
      fontSize: '1.2rem',
      fontWeight: '600',
    },
    '& .body': {
      color: theme.palette.text.secondary,
      fontSize: '1rem',
    },
  },
  arrowContainer: {
    padding: '1rem',
    backgroundColor: 'rgba(43, 24, 65, 0.06)',
    borderRadius: '100px',
    display: 'inline-flex',
    alignSelf: 'center',
    color: 'black',
  },
  center: {
    alignSelf: 'center',
  },
}))

export default function ExchangesSection(): JSX.Element {
  const classes = useStyles({})
  return (
    <section className={classes.root}>
      <div>
        <div className={classes.titleSection}>
          <Chip label="Exchanges" classes={{ root: classes.chip }} />
          <Typography variant="body2" className={classes.title}>
            Buy cryptocurrency using conventional payment methods.
          </Typography>
          <Link underline="none" href={urls.exchanges} className={classes.link}>
            Learn more about exchanges
          </Link>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <div className={classes.learnCard}>
              <div>
                <Image src="/svg/crypto-exchange.svg" layout="intrinsic" height={64} width={64} />
              </div>
              <Typography variant="body2" className="title">
                Cryptocurrency exchanges
              </Typography>
              <Typography variant="body2" className="body">
                Crypto Exchanges allow you to buy at the best market rate cryptocurrency using
                conventional payment methods like by bank transfer or credit card.
              </Typography>
              <Link href={urls.exchanges} underline="none" className={classes.link}>
                Learn more about crypto exchanges
              </Link>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.learnCard}>
              <div>
                <Image src="/svg/p2p-exchange.svg" layout="intrinsic" height={64} width={64} />
              </div>
              <Typography variant="body2" className="title">
                Peer to Peer exchanges
              </Typography>
              <Typography variant="body2" className="body">
                Peer-to-peer exchanges are marketplaces where people can trade crypto directly with
                each other.
              </Typography>
              <Link underline="none" href={urls.exchanges} className={classes.link}>
                Learn more about crypto exchanges
              </Link>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.learnCard}>
              <div>
                <Image src="/svg/d-exchange.svg" layout="intrinsic" height={64} width={64} />
              </div>
              <Typography variant="body2" className="title">
                Decentralized exchanges
              </Typography>
              <Typography variant="body2" className="body">
                Decentralized Exchanges allow you to trade assets without having to go through a
                middleman. Trades happen on the Blockchain and are free from regulation.
              </Typography>
              <Link underline="none" href={urls.exchanges} className={classes.link}>
                Learn more about crypto exchanges
              </Link>
            </div>
          </Grid>
        </Grid>
      </div>
      <div>
        <div className={classes.titleSection}>
          <Chip label="Wallets" classes={{ root: classes.chip }} />
          <Typography variant="body2" className={classes.title}>
            Custodial vs Non-custodial wallets
          </Typography>
          <Link underline="none" href={urls.productWallets} className={classes.link}>
            See the whole guide
          </Link>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <div className={classes.learnCard}>
              <div>
                <Image src="/svg/custodial.svg" layout="intrinsic" height={64} width={64} />
              </div>
              <Typography variant="body2" className="body">
                Custodial wallets are like banks. The provider controls your assets and saves them
                for you.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.learnCard}>
              <div>
                <Image src="/svg/non-custodial.svg" layout="intrinsic" height={64} width={64} />
              </div>
              <Typography variant="body2" className="body">
                Non-custodial wallets allow you to keep complete ownership of your funds. Your funds
                are stored on the Blockchain.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <Link underline="none" href={urls.productWallets} className={`${classes.center}`}>
              <div className={classes.learnCard}>
                <Typography variant="body2" className={`title ${classes.center}`}>
                  Compare Wallets
                </Typography>

                <div className={`${classes.center} ${classes.arrowContainer}`}>
                  <ArrowForward />
                </div>
              </div>
            </Link>
          </Grid>
        </Grid>
      </div>
    </section>
  )
}
