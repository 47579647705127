import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Image from 'next/image'
import { Typography, Grid, Link } from '@material-ui/core'
import ArrowForward from '@material-ui/icons/ArrowForward'
import ProductGrid from './ProductGrid'
import { Exchange as ExchangeType } from '../../../types/exchange'
import { Wallet as WalletType } from '../../../types/wallet'
import { Deal as DealType } from '../../../types/deal'
import { StakingPlatform as PlatformType } from '../../../types/stakingPlatform'
import sampleExchanges from './sampleExchanges.json'
import { SwitchTransition, CSSTransition } from 'react-transition-group'
import { SavingAccount } from '../../../types/savingAccount'
import { urls } from '../../utils/config'
import { CryptoCard } from '../../../types/cryptoCard'

const CURRENT_VIEW = {
  EXCHANGES: 0,
  WALLETS: 1,
  EARN: 2,
  REWARDS: 3,
}

type productsType = (WalletType & ExchangeType & DealType & PlatformType & CryptoCard)[]

const useStyles = makeStyles(() => ({
  root: {},
  navContainer: {
    background: 'inherit',
    zIndex: 2,
  },
  navSection: {},
  allLink: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    margin: '1rem 0',
    '& > a': {
      color: 'white',
      display: 'flex',
      alignItems: 'center',
    },

    '& > a > *': {
      fontSize: '.8rem',
    },
  },
  navItem: {
    display: 'flex',
    flexDirection: 'row',
    padding: '.8rem 1rem',
    margin: '.4rem 0',
    borderRadius: '.8rem',
    transition: 'all 0.1s ease-in-out',
    '&:hover, &:focus': {
      background: 'rgba(255, 255, 255, 0.4)',
      transition: 'all 0.1s ease-in-out',
    },
    '&.active': {
      background: 'white',
      transition: 'all 0.2s ease-in-out',

      '& $navTitle': {
        color: 'black',
      },
      '& $navSubTitle': {
        color: 'rgba(0, 0, 0, 0.4)',
      },
    },
    '& > div': {
      marginLeft: '.8rem',
    },
  },
  navTitle: {
    color: 'white',
    fontSize: '1rem',
  },
  navSubTitle: {
    color: 'rgba(255, 255, 255, 0.4)',
    fontSize: '1rem',
  },
  productsContainer: {
    minHeight: '400px',
  },
  contentContainer: {
    margin: ' 0 -40px',
  },
}))

type Props = {
  wallets: WalletType[]
  exchanges: ExchangeType[]
  savingAccounts: SavingAccount[]
  cards: CryptoCard[]
}

export default function Explorer({
  wallets,
  exchanges,
  savingAccounts,
  cards,
}: Props): JSX.Element {
  const [currentView, setCurrentView] = useState(CURRENT_VIEW.EXCHANGES)
  const classes = useStyles({})

  const handleNavItemClick = (view: number) => {
    setCurrentView(view)
  }

  const displayedProducts = () => {
    let products
    switch (currentView) {
      case CURRENT_VIEW.EXCHANGES:
        products = exchanges.slice(0, 9)
        break
      case CURRENT_VIEW.WALLETS:
        products = wallets.slice(0, 9)
        break
      case CURRENT_VIEW.EARN:
        products = savingAccounts.slice(0, 9)
        break
      case CURRENT_VIEW.REWARDS:
        products = cards.slice(0, 9)
        break
      default:
        products = sampleExchanges.slice(0, 9)
        break
    }
    return (products as unknown) as productsType
  }

  const seeAllLink = () => {
    let link = {
      label: '',
      url: '',
    }
    switch (currentView) {
      case CURRENT_VIEW.EXCHANGES:
        link = {
          label: 'See all exchanges',
          url: urls.productExchanges,
        }
        break
      case CURRENT_VIEW.WALLETS:
        link = {
          label: 'See all wallets',
          url: urls.productWallets,
        }
        break
      case CURRENT_VIEW.EARN:
        link = {
          label: 'See all savings accounts',
          url: urls.productEarn,
        }
        break
      case CURRENT_VIEW.REWARDS:
        link = {
          label: 'See all rewards',
          url: urls.productRewards,
        }
        break
      default:
        link = {
          label: 'See all exchanges',
          url: urls.productExchanges,
        }
        break
    }

    return link
  }

  return (
    <div className={classes.root}>
      <div className={classes.allLink}>
        <Link href={seeAllLink().url}>
          <Typography variant="body2">{seeAllLink().label}</Typography>
          <ArrowForward fontSize="small" />
        </Link>
      </div>

      <Grid className={classes.contentContainer} container spacing={10}>
        <Grid item md={4} className={classes.navContainer}>
          <Link
            className={classes.navSection}
            color="inherit"
            href="#"
            onClick={(e: React.MouseEvent) => {
              e.preventDefault()
              handleNavItemClick(CURRENT_VIEW.EXCHANGES)
            }}
            underline="none"
          >
            <div
              className={`${classes.navItem} ${
                currentView === CURRENT_VIEW.EXCHANGES ? 'active' : ''
              } `}
            >
              <Image src="/svg/product/exchanges-icon.svg" height="30px" width="30px" />
              <div>
                <Typography variant="body2" className={classes.navTitle}>
                  Exchanges
                </Typography>
                <Typography variant="body2" className={classes.navSubTitle}>
                  Buy & Sell
                </Typography>
              </div>
            </div>
          </Link>
          <Link
            className={classes.navSection}
            color="inherit"
            href="#"
            onClick={(e: React.MouseEvent) => {
              e.preventDefault()
              handleNavItemClick(CURRENT_VIEW.WALLETS)
            }}
            underline="none"
          >
            <div
              className={`${classes.navItem} ${
                currentView === CURRENT_VIEW.WALLETS ? 'active' : ''
              } `}
            >
              <Image src="/svg/product/wallets-icon.svg" height="30px" width="30px" />
              <div>
                <Typography variant="body2" className={classes.navTitle}>
                  Wallets
                </Typography>
                <Typography variant="body2" className={classes.navSubTitle}>
                  Store & Send
                </Typography>
              </div>
            </div>
          </Link>
          <Link
            className={classes.navSection}
            color="inherit"
            href="#"
            onClick={(e: React.MouseEvent) => {
              e.preventDefault()
              handleNavItemClick(CURRENT_VIEW.EARN)
            }}
            underline="none"
          >
            <div
              className={`${classes.navItem} ${currentView === CURRENT_VIEW.EARN ? 'active' : ''} `}
            >
              <Image src="/svg/product/earn-icon.svg" height="30px" width="30px" />
              <div>
                <Typography variant="body2" className={classes.navTitle}>
                  Earn
                </Typography>
                <Typography variant="body2" className={classes.navSubTitle}>
                  Staking & Lending
                </Typography>
              </div>
            </div>
          </Link>
          <Link
            className={classes.navSection}
            color="inherit"
            href="#"
            onClick={(e: React.MouseEvent) => {
              e.preventDefault()
              handleNavItemClick(CURRENT_VIEW.REWARDS)
            }}
            underline="none"
          >
            <div
              className={`${classes.navItem} ${
                currentView === CURRENT_VIEW.REWARDS ? 'active' : ''
              } `}
            >
              <Image src="/svg/product/rewards-icon.svg" height="30px" width="30px" />
              <div>
                <Typography variant="body2" className={classes.navTitle}>
                  Rewards
                </Typography>
                <Typography variant="body2" className={classes.navSubTitle}>
                  Cashback & Discounts
                </Typography>
              </div>
            </div>
          </Link>
        </Grid>
        <Grid item md={8} className={classes.productsContainer}>
          <SwitchTransition>
            <CSSTransition
              mode="out-in"
              key={currentView}
              addEndListener={(node, done) => {
                node.addEventListener('transitionend', done, false)
              }}
              classNames="product-cards-container"
            >
              <ProductGrid products={displayedProducts()} />
            </CSSTransition>
          </SwitchTransition>
        </Grid>
      </Grid>
    </div>
  )
}
