import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Exchange as ExchangeType } from '../../../types/exchange'
import { Wallet as WalletType } from '../../../types/wallet'
import { Deal as DealType } from '../../../types/deal'
import { StakingPlatform as PlatformType } from '../../../types/stakingPlatform'
import Image from 'next/image'
import { LinearProgress, Typography, withStyles, Grid, Link } from '@material-ui/core'
import { CryptoCard as CryptoCardType } from '../../../types/cryptoCard'

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 6,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[800],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.main,
  },
}))(LinearProgress)

const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: 'column',
    background: 'rgba(255, 255, 255, 0.1)',
    borderRadius: '12px',
    padding: '2rem 1rem',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      padding: '1rem',
    },
  },
  imageWrapper: {
    padding: '.8rem',
    background: 'white',
    borderRadius: '.6rem',
    width: '4rem',
    height: '4rem',

    [theme.breakpoints.up('md')]: {
      marginRight: '1rem',
    },
  },
  imageContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    position: 'relative',
    overflow: 'hidden',
  },
  detailsContainer: {
    width: '100%',
    textAlign: 'center',
    '& > p': {
      color: 'white',
      margin: '.4rem 0',
      fontSize: '.8rem',
      [theme.breakpoints.up('md')]: {},
    },
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
      width: '60%',
    },
  },
  linearProgressContainer: {
    '& p': {
      color: 'white',
      fontSize: '.8rem',
      fontWeight: '800',
    },
  },
}))

interface props {
  product: ExchangeType & WalletType & DealType & PlatformType & CryptoCardType
}

function ProductCard({ product }: props): JSX.Element {
  const classes = useStyles({})

  return (
    <Link className={classes.root} href={product.url} underline="none">
      <div className={classes.imageWrapper}>
        <div className={classes.imageContainer}>
          <Image
            src={
              product?.icon?.url
                ? product.icon.url
                : product?.logo
                ? product.logo.url
                : product?.image
                ? product.image.url
                : product?.card_image.url
                ? product.card_image.url
                : '/svg/wallet.svg'
            }
            objectFit="contain"
            layout="fill"
          />
        </div>
      </div>
      <div className={classes.detailsContainer}>
        <Typography variant="body2">{product?.title || product?.name || ''}</Typography>
        {product?.features_rating && (
          <Grid
            container
            spacing={1}
            alignItems="center"
            className={classes.linearProgressContainer}
          >
            <Grid item xs={10}>
              <BorderLinearProgress
                variant="determinate"
                value={(Number.parseFloat(product?.features_rating) / 5) * 100}
              />
            </Grid>
            <Grid item xs={2}>
              <Typography variant="body2">{product?.features_rating}</Typography>
            </Grid>
          </Grid>
        )}
      </div>
    </Link>
  )
}

export default ProductCard
