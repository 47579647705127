import React from 'react'
import Image from 'next/image'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  testimonial: {
    '& p': {
      color: 'white',
      fontFamily: theme.typography.h1.fontFamily,
      '&.quote': {
        fontSize: '2.25rem',
        fontWeight: '800',
      },
      '&.author': {
        marginTop: '1rem',
        fontSize: '1rem',
        fontWeight: '800',
      },
      '&.position': {
        fontSize: '1rem',
      },
    },
    '& .avatar': {
      position: 'relative',
    },
  },
}))

export default function Testimonial(): JSX.Element {
  const classes = useStyles({})
  return (
    <Grid container spacing={2} className={classes.testimonial}>
      <Grid item xs={12} md={6}>
        <Typography variant="body2" color="textSecondary" className="quote">
          &quot;Cryptotesters has on-boarded thousands of people into Crypto and DeFi. It is
          hands-down one of the best educational platforms in the space.&quot;
        </Typography>
        <Typography variant="body2" color="textSecondary" className="author">
          Peter Smith
        </Typography>
        <Typography variant="body2" color="textSecondary" className="position">
          CEO, Blockchain.com
        </Typography>
      </Grid>
      <Grid item container xs={12} md={6} justify="center" alignItems="center">
        <Image src="/testimonial-avatar.png" height={320} width={213} layout="intrinsic" />
      </Grid>
    </Grid>
  )
}
