import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import ExploreNavCard from './ExploreNavCard'
import { urls } from '../../utils/config'

const useStyles = makeStyles((theme) => ({
  gridItem: {
    [theme.breakpoints.up('md')]: {
      flexGrow: 0,
      maxWidth: '20%',
      flexBasis: '25%',
    },
  },
}))

export type linkType = {
  image: {
    url: string
  }
  title: string
  url: string
}

const links: linkType[] = [
  {
    image: {
      url: '/home-icons/academy.png',
    },
    title: 'Academy',
    url: urls.academy,
  },
  {
    image: {
      url: '/home-icons/articles.png',
    },
    title: 'Articles',
    url: urls.blog,
  },
  {
    image: {
      url: '/home-icons/reviews.png',
    },
    title: 'Reviews',
    url: urls.review,
  },
  {
    image: {
      url: '/home-icons/community.png',
    },
    title: 'Community',
    url: urls.community,
  },
  {
    image: {
      url: '/home-icons/exchange.png',
    },
    title: 'Exchanges',
    url: urls.exchanges,
  },
  {
    image: {
      url: '/home-icons/wallets.png',
    },
    title: 'Wallets',
    url: urls.custodialWallets,
  },
  {
    image: {
      url: '/home-icons/savings.png',
    },
    title: 'Savings',
    url: urls.savingAccounts,
  },
  {
    image: {
      url: '/home-icons/rewards.png',
    },
    title: 'Rewards',
    url: urls.ethereumStaking,
  },
]

function TopicGrid(): JSX.Element {
  const classes = useStyles({})
  return (
    <Grid container justify="flex-start" spacing={2}>
      {links.map((link, index) => (
        <Grid item key={index} xs={6} md={3}>
          <ExploreNavCard link={link} />
        </Grid>
      ))}
    </Grid>
  )
}

export default TopicGrid
