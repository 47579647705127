import React from 'react'
import { Typography, Link, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Image from 'next/image'
import { urls } from '../../utils/config'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '20px',
    [theme.breakpoints.up('sm')]: {
      padding: '120px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '160px',
    },
  },
  sectionTitle: {
    fontFamily: theme.typography.h1.fontFamily,
    fontWeight: 'bold',
  },
  image: {
    width: '100%',
    height: 'inherit',
    position: 'relative',
    borderRadius: '4px',
  },
  textSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '32rem',
    [theme.breakpoints.up('md')]: {
      minHeight: '24rem',
    },
  },
  imageSection: {
    display: 'none',
    justifyContent: 'center',
    alignContent: 'center',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  link: {
    fontWeight: 'bold',
  },
  button: {
    borderRadius: '8px',
    fontFamily: 'Inter',
    textTransform: 'none',
    fontSize: '14px',
    fontWeight: 'bold',
    border: 0,
    letterSpacing: '0.5px',
    lineHeight: 1.3,
    padding: '1rem',
    boxShadow: 'none',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}))

function JoinCommunitySection(): JSX.Element {
  const classes = useStyles({})
  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6} className={classes.textSection}>
          <Typography className={classes.sectionTitle} variant="h2">
            Want to be a crypto-tester?
          </Typography>
          <Typography variant="subtitle2" color="textSecondary">
            Join our tight-knit and private community of crypto-testers and stay close to what is
            happening in the crypto space.
          </Typography>
          <div>
            <Link
              href={`${urls.community}`}
              color="textSecondary"
              component="a"
              className={classes.button}
              underline="none"
            >
              Join the community
            </Link>
          </div>
        </Grid>
        <Grid item xs={12} md={6} className={classes.imageSection}>
          <div className={classes.image}>
            <Image layout="fill" objectFit="contain" src={'/home-icons/community.png'} />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default JoinCommunitySection
